import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import api from "../../../axios";
function Login() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    username_or_email: "",
    password: "",
  });
  const [errors, setError] = useState({
    username_or_email: "",
    password: "",
  });
  const submitHandler = () => {
    let error = false;
    if (data.username_or_email.trim().length === 0) {
      errors.username_or_email = "username or email is required";
      error = true;
    } else {
      errors.username_or_email = "";
    }
    if (data.password.trim().length === 0) {
      errors.password = "password is required";
      error = true;
    } else {
      errors.password = "";
    }
    if (error) {
      setError({ ...errors });
    } else {
      api
        .post("/auth/login", {
          username_or_email: data.username_or_email.trim(),
          password: data.password.trim(),
        })
        .then((res) => {
          if (res.data.status === true) {
            localStorage.setItem("OneAppJWTtoken", res.data.JWTtoken);
            navigate("/home");
          }
        })
        .catch((res) => {
          setError({
            ...errors,
            [res.response.data.field]: res.response.data.message,
          });
        });
    }
  };
  return (
    <div className="page">
      <div className="form-container">
        <h1 className="heading">Welcome to OneApp</h1>
        <p className="sub-heading">Login your account</p>
        <p className="step"></p>
        <label className="label">Email or username</label>
        <input
          className="input"
          type="email"
          placeholder="Enter email or username"
          onChange={(e) => {
            setData({ ...data, username_or_email: e.target.value });
          }}
          value={data.username_or_email}
        />
        {errors.username_or_email.length > 0 && (
          <li className="error">{errors.username_or_email}</li>
        )}
        <label className="label">Password</label>
        <input
          className="input"
          type="password"
          placeholder="Enter Your password"
          onChange={(e) => {
            setData({ ...data, password: e.target.value });
          }}
          value={data.password}
        />
        {errors.password.length > 0 && (
          <li className="error">{errors.password}</li>
        )}
        <div className="btn-group">
          <button onClick={(_) => navigate("/")}>Back</button>
          <button onClick={(_) => submitHandler()}>Finish</button>
        </div>
      </div>
    </div>
  );
}

export default Login;
