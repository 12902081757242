import React, { useState } from 'react';
import './user.css'
import BasePage from '../../BasePage/BasePage';
import { Link } from 'react-router-dom';
import { Modal } from "flowbite-react";

function ProfileHeader() {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="profile-header">
      <img src="profile-picture-url" alt="Profile" className="profile-picture" />
      <div className="profile-info">
        <h2>Nikhil D</h2>
        <p>@NikhilD</p>
        <b>Video creator</b>
        <div >
          <p>100K followers</p>
          <button className="info-btns">Follow</button><button className="info-btns">Message</button></div>
        <div className="description">
          <p>Everything is example. Design by Nikhil.fsdaffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsdfsdfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff</p>
          <div className='desc-actions'>
            <Link className='desc-more-btn' onClick={() => setOpenModal(true)}>More</Link>
            <Link className='desc-links'>Links</Link>
          </div>
        </div>
        <Modal show={openModal} onClick={(e) => {
          setOpenModal(false)
        }} onClose={() => setOpenModal(false)} className='modal-bg'>

          <Modal.Body onClick={(e) => {
            e.stopPropagation()
          }} className='modal'>
            <div className="about-content">
              <p className='about-heading'>About</p>
              <p className="desc-more">
                With less than a month to go before the European Union enacts new consumer privacy laws for its citizens,
                companies around the world are updating their terms of service agreements to comply.

                The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant
                to ensure a common set of data rights in the European Union. It requires organizations to notify users as
                soon as possible of high-risk data breaches that could personally affect them.
              </p>

              <p className='about-heading'>Links</p>
              <div className='input-group'>
              <input type="checkbox" name="" id="" />
              <input type="checkbox" name="" id="" /></div>
            </div>
          </Modal.Body>

        </Modal>
        {/*  */}
      </div>
    </div>
  );
}

const ActionButtons = () => (
  <div className="action-buttons">
    {/* <button className="message-btn">Message</button>
    <button className="links-btn">More Links</button> */}
  </div>
);

const Tabs = ({ activeTab, setActiveTab }) => (
  <div className="tabs">
    {['Thoughts', 'Moments', 'Beeps', 'Videos'].map((tab) => (
      <button
        key={tab}
        className={activeTab === tab ? 'active' : ''}
        onClick={() => setActiveTab(tab)}
      >
        {tab}
      </button>
    ))}
  </div>
);

const Content = ({ activeTab }) => {
  switch (activeTab) {
    case 'Thoughts':
      return <div>Thoughts Content</div>;
    case 'Moments':
      return <div>Moments Content</div>;
    case 'Beeps':
      return <div>Beeps Content</div>;
    case 'Videos':
      return <div>Videos Content</div>;
    default:
      return null;
  }
};

const UserPage = () => {
  const [activeTab, setActiveTab] = useState('Thoughts');

  return (
    <BasePage content={
      <div className="profile-container">
        <ProfileHeader />
        <ActionButtons />
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <Content activeTab={activeTab} />
      </div>} />
  );
};
export default UserPage;