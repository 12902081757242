import React from 'react'
import { BiUser } from "react-icons/bi";
import { HiDotsVertical } from "react-icons/hi";
import chatData from './ChatData';
import MessageInput from './MessageInput';


const MessageDetail = ({username,time,message}) => {

  // Today Date
  const todayDate = new Date()
  const today = todayDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  // Yesterday Date
  const yesterdayDate = new Date(todayDate)
  yesterdayDate.setDate(todayDate.getDate() - 1)

  const yesterday = yesterdayDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
  

  const userChatData = chatData[username]  

  const sortedDates = Object.keys(userChatData).sort((a, b) => new Date(a) - new Date(b) )
  
  return (
    <div>
        <div 
          className="message-headers px-4  flex gap-3 items-center h-[63px] border-b cursor-pointer">
          <div className='border-2 rounded-full w-[53px] h-[49px] flex items-center justify-center'>
            <BiUser className='text-4xl'/>
          </div>

          {/* username and last message */}
          <div className='w-full'>
            <div className='flex justify-between items-center '>
              <div>
                <h2 className='font-bold text-[20px] '>{username}</h2>
                <p >last seen at 3:52 pm</p>
              </div>
              <div className='text-[20px]'>
                <HiDotsVertical className=' text-3xl' />
              </div>
            </div>
          </div>
        </div>

        {/* Chat Messages */}
        <div>
          {
            sortedDates.map((date) => (
              <div key={date} >
                <h2 className='text-center'>{
                  today === date ? 'Today' : ( yesterday === date ? 'Yesterday' : date )
                }</h2>
                {
                  userChatData[date].map((chat, index) => (
                    <div className= {`flex flex-col px-4 gap-1 ${chat.sender === username ? 'items-end' : 'items-start' }`}>
                      <p key={index} className={`p-3 rounded-lg font-medium ${chat.sender === username ? 'bg-customBlue' : 'bg-white text-black' }`} >
                        {chat.content} 
                      </p>
                      <span className="text-sm text-white">{chat.time}</span>
                      <span></span>
                    </div>
                  ))
                }
              </div>
            ))
          }
          
          <div className='bottom-0 fixed w-[1020px]'>
            <MessageInput  />
          </div>

        </div>
     
    </div>
  )
}

export default MessageDetail
