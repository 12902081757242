import React from 'react'
import './Home.css'
import BasePage from '../../BasePage/BasePage'
import Post from '../../components/Post/Post';
function Home() {
   
    return (
        
        <BasePage content={
            <>
                <Post />
            </>
        } />
    )
}

export default Home