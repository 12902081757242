import React, { useContext } from 'react'
import BasePage from '../../BasePage/BasePage'
import FandF from '../../components/message/FandF'
import { MessageContext } from '../../context/MessageContext'
import MessageDetail from '../../components/message/MessageDetail'
import Primary from '../../components/message/Primary'
import Requests from '../../components/message/Requests'

const Message = () => {

    const { state,dispatch } = useContext(MessageContext)
    const { activeSection, selectedMessage } = state

    const handleFandFClick = () => {
        dispatch({ type: 'SET_ACTIVE_SECTION', payload: 'F&F' });
        dispatch({ type: 'SET_SELECT_MESSAGE', payload: null  });

    }
    const handlePrimaryClick = () => {
        dispatch({ type: 'SET_ACTIVE_SECTION', payload: 'Primary' });
        dispatch({ type: 'SET_SELECT_MESSAGE', payload: null  });
    }

    const handleRequestsClick = () => {
        dispatch({ type: 'SET_ACTIVE_SECTION', payload: 'Requests'  });
        dispatch({ type: 'SET_SELECT_MESSAGE', payload: null  });

    }

    console.log('current section : ',activeSection);
    

  return (
    <BasePage content = {
        <div>
            <div className="sections flex gap-24 ps-7 border-b border-white mt-5">
                <div onClick={handleFandFClick} className={`cursor-pointer ${activeSection === 'F&F' ? 'font-bold border-b-2' : '' } `}>
                    <p>F&F</p>
                </div>
                <div onClick={handlePrimaryClick} className={`cursor-pointer ${activeSection === 'Primary' ? 'font-bold border-b-2' : '' } `}>
                    <p>Primary</p>
                </div>
                <div onClick={handleRequestsClick} className={`cursor-pointer ${activeSection === 'Requests' ? 'font-bold border-b-2' : '' } `}>
                    <p>Requests</p>
                </div>
            </div>

            <div className='flex'>
                <div className='w-[30%] h-screen border-r border-white'>
                    {
                        activeSection === 'F&F' && <FandF />
                    }
                    {
                        activeSection === 'Primary' && <Primary />
                    }
                    {
                        activeSection === 'Requests' && <Requests />
                    }
                </div>
                <div className='w-[70%]'>
                    {
                        selectedMessage ? (
                            <MessageDetail 
                              username = {selectedMessage.username}
                              message = {selectedMessage.message}
                              time = {selectedMessage.time}
                             />
                        ) : (
                            <p className='p-12'>Select a message to view details </p>
                        )
                    }
                    
                </div>
            </div>

        </div>
    } />
  )
}

export default Message
