const chatData = {
    "user_2": {
      "October 15, 2024": [
        {
          "sender": "user_1",
          "receiver": "user_2",
          "content": "Hey, how are you?",
          "time": "09:30 AM",
          "timestamp": "2024-10-14T09:30:00"
        },
        {
          "sender": "user_2",
          "receiver": "user_1",
          "content": "I'm good! How about you?",
          "time": "09:32 AM",
          "timestamp": "2024-10-14T09:32:00"
        }
      ],
      "October 14, 2024": [
        {
          "sender": "user_1",
          "receiver": "user_2",
          "content": "Let's prepare for the quiz.",
          "time": "06:10 PM",
          "timestamp": "2024-10-13T18:10:00"
        },
        {
          "sender": "user_2",
          "receiver": "user_1",
          "content": "Sure! I'm ready.",
          "time": "06:15 PM",
          "timestamp": "2024-10-13T18:15:00"
        },
        {
          "sender": "user_2",
          "receiver": "user_1",
          "content": "I found a great resource for the quiz.",
          "time": "06:45 PM",
          "timestamp": "2024-10-13T18:45:00"
        }
      ],
      "October 12, 2024": [
        {
          "sender": "user_1",
          "receiver": "user_2",
          "content": "Don't forget to review today's quiz material.",
          "time": "05:30 PM",
          "timestamp": "2024-10-12T17:30:00"
        },
        {
          "sender": "user_2",
          "receiver": "user_1",
          "content": "Thanks for reminding me!",
          "time": "05:35 PM",
          "timestamp": "2024-10-12T17:35:00"
        }
      ],
      
    },
    "user_3": {
      "October 15, 2024": [
        {
          "sender": "user_1",
          "receiver": "user_3",
          "content": "Did you complete the quiz?",
          "time": "10:15 AM",
          "timestamp": "2024-10-14T10:15:00"
        },
        {
          "sender": "user_3",
          "receiver": "user_1",
          "content": "Yes! It was quite interesting.",
          "time": "10:20 AM",
          "timestamp": "2024-10-14T10:20:00"
        }
      ],
      "October 14, 2024": [
        {
          "sender": "user_1",
          "receiver": "user_3",
          "content": "Can you help me with question 3?",
          "time": "08:00 PM",
          "timestamp": "2024-10-13T20:00:00"
        },
        {
          "sender": "user_3",
          "receiver": "user_1",
          "content": "Of course! Here's how to solve it...",
          "time": "08:05 PM",
          "timestamp": "2024-10-13T20:05:00"
        }
      ]
    },
    "user_4": {
      "October 15, 2024": [
        {
          "sender": "user_1",
          "receiver": "user_4",
          "content": "Are you joining today's quiz competition?",
          "time": "11:45 AM",
          "timestamp": "2024-10-14T11:45:00"
        },
        {
          "sender": "user_4",
          "receiver": "user_1",
          "content": "Yes, I'll be there!",
          "time": "12:00 PM",
          "timestamp": "2024-10-14T12:00:00"
        }
      ],
      "October 12, 2024": [
        {
          "sender": "user_1",
          "receiver": "user_4",
          "content": "Are you joining the quiz meeting?",
          "time": "09:00 AM",
          "timestamp": "2024-10-12T09:00:00"
        },
        {
          "sender": "user_4",
          "receiver": "user_1",
          "content": "I might be late, but I'll try.",
          "time": "09:05 AM",
          "timestamp": "2024-10-12T09:05:00"
        }
      ]
    }
  };
  
  export default chatData;
  