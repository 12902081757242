import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserRoutes from "./routes/UserRoutes";
import { MessageProvider } from "./context/MessageContext";

function App() {
  return (
    <Router>
      <MessageProvider>
      <Routes>
        <Route path="/*" element={<UserRoutes />} />
      </Routes>
      </MessageProvider>
    </Router>
  );
}

export default App;
