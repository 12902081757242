import React, { useState } from 'react'
import logo from '../../Assets/Brandings/logo_large.png'
import './Header.css'
import SearchIcon from '../../Assets/Icons/Search.svg'
import AddIcon from '../../Assets/Icons/Add.svg'
import NotificationIcon from '../../Assets/Icons/Notifications.svg'
import { useNavigate } from 'react-router-dom'
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/material'
import thoughtsIcon from '../../Assets/Icons/Thoughts.svg'
import messagesIcon from '../../Assets/Icons/Messages.svg'
import savedIcon from '../../Assets/Icons/Saved.svg'
import editProfileIcon from '../../Assets/Icons/EditProfile.svg'
import SettingsIcon from '../../Assets/Icons/Settings.svg'
import helpIcon from '../../Assets/Icons/Help.svg'
import { Modal } from 'flowbite-react'


function Header() {
    const naviagate = useNavigate()
    const [openModal, setOpenModal] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const toggleDrawer = (open) => (event) => {


        setOpen(prev => !prev);
    };
    return (
        <>
            <div className='header'>
                <div className='header-leading'>
                    <button className='slider-btn' onClick={toggleDrawer(true)}><i class="fa-solid fa-bars" ></i></button>

                    <img src={logo} alt="" srcset="" className='logo' onClick={_ => naviagate('/home')} />
                </div>
                <div className='search'>

                    <input type="text" placeholder='Search' />
                    <img src={SearchIcon} alt="" />
                </div>
                <div className='trailing'>
                    <img src={SearchIcon} alt="" className='search-icon' />
                    <img src={AddIcon} alt="" className='add' />
                    <img src={NotificationIcon} onClick={_=>setOpenModal(true)} alt="" className='notification-icon' />
                    <div className='profile' onClick={_ => naviagate('/profile')}></div>
                </div>
            </div>
            <React.Fragment key={'left'} >
                <Drawer
                    className='sidedraw-slider'
                    anchor={'left'}
                    open={isOpen}
                    onClose={toggleDrawer(false)}
                >

                    <Box
                        className='drawer-box'
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    ><div className='drawer-header'>
                            <p className='close-btn' onClick={toggleDrawer(true)}>X</p>
                            {/* <img src={ArrowLeft}/> */}
                            <img src={logo} alt="" srcset="" className='drawer-logo' />
                        </div>

                        <div className='sidedraw-grid'>
                            <div className="sidedraw-cell">
                                <img src={thoughtsIcon} alt='thoughts' />
                                <p>Thoughts</p>
                            </div>

                            <div className="sidedraw-cell">
                                <img src={messagesIcon} alt='messages' />
                                <p>Messages</p>
                            </div>

                            <div className="sidedraw-cell">
                                <img src={savedIcon} alt='saved' />
                                <p>Saved</p>
                            </div>

                            <div className="sidedraw-cell">
                                <img src={editProfileIcon} alt='edit profile' />
                                <p>Edit Profile</p>
                            </div>



                            <div className="sidedraw-cell">
                                <img src={SettingsIcon} alt='settings' />
                                <p>Settings</p>
                            </div>
                            <div className="sidedraw-cell">
                                <img src={helpIcon} alt='help' />
                                <p>Help</p>
                            </div>
                        </div>

                    </Box>
                </Drawer>
            </React.Fragment>
            <Modal show={openModal} onClick={(e) => {
                setOpenModal(false)
            }} onClose={() => setOpenModal(false)} className='notification-bg'>

                <Modal.Body onClick={(e) => {
                    e.stopPropagation()
                }} className='notification'>
                    <div className='notification-header'>Notifications</div>
                    
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Header