import React from 'react'
import BasePage from '../../BasePage/BasePage'

const Settings = () => {
  return (
    <BasePage content = {
        <div>
            this is settings page
        </div>
    } />
  )
}

export default Settings
