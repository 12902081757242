import React from 'react'
import './Landing.css'
import Logo from '../../../Assets/Brandings/logo_large.png'
import { useNavigate } from 'react-router-dom'

function Landing() {
    const navigate=useNavigate()
    return (
        <div className='page'>
            <div className="main-container">
                <img src={Logo} alt='One App' className='logo-image' />
                <button className='btn'
                onClick={_=>navigate('/signup')}
                >Create new account</button>
                <h5 className='or'>OR</h5>
                <div className='signin-container'>
                    <p>Already have an account?</p>
                    <button className='btn'
                    onClick={_=>navigate('/login')}
                    >Login</button>
                </div>
            </div>
        </div>
    )
}

export default Landing