import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "../pages/auth/Landing/Landing";
import Signup from "../pages/auth/Signup/Signup";
import GetMobile from "../pages/auth/Signup/GetMobile";
import VerifyOTP from "../pages/auth/Signup/VerifyOTP";
import GetUsername from "../pages/auth/Signup/GetUsername";
import Home from "../pages/home/Home";
import Login from "../pages/auth/Login/Login";
import UserProfile from "../pages/profile/profile";
import Message from "../pages/message/Message";
import Thoughts from "../pages/thoughts/Thoughts";
import Saved from "../pages/saved/Saved";
import Settings from "../pages/settings/Settings";
import Help from "../pages/help/Help";
import UserPage from "../pages/user/user";
function UserRoutes() {
  return (
    <>
    
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/get-mobile" element={<GetMobile />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/get-username" element={<GetUsername />} />
        <Route path="/home" element={<Home />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/message" element={<Message />} />
        <Route path="/thoughts" element={<Thoughts />} />
        <Route path="/saved" element={<Saved />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/help" element={<Help />} />

      </Routes>
    </>
  );
}

export default UserRoutes;
