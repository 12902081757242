import React, { useState } from "react";
import "./Post.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "../../axios"; 
import ForYou from "./ForYou";
import Following from "./Following";

const Post = () => {
  const [data, setData] = useState([]);
  const [thoughts, setThoughts] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [postSection, setPostSection] = useState(true);

  const handleForyou = () => {
    setPostSection(true);
  };

  const handleFollowing = () => {
    setPostSection(false);
  };

  const handleAddPost = async () => {
    const formData = new FormData();
    formData.append("thoughts", thoughts);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    try {
      const response = await axios.post("/add-post", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      
      const newPost = response.data.post;
      setData((prevData) => [newPost, ...prevData]);  
      setThoughts("");
      setSelectedImage(null);

      console.log("Post submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting post:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  return (
    <div className="flex">
      <div className="post w-[65%]">
        <div className="flex justify-around border-b pt-4 list-none">
          <li className={`cursor-pointer ${postSection ? 'font-bold border-b-2 ' : '' }`} onClick={handleForyou}>For You</li>
          <li className={`cursor-pointer ${!postSection ? 'font-bold border-b-2' : '' }`} onClick={handleFollowing}>Following</li>
        </div>

        <div className="py-3 border-r h-screen post-list  no-scrollbar ">
          <div className="">
            {postSection ? <ForYou setData={setData} data={data} /> : <Following setData={setData} data={data}/>}
          </div>
        </div>
      </div>

      <div className="w-[35%] p-4 border-t mt-[42px]">
        <div className="add-post border">
          <textarea
            rows={'100'}
            type="text"
            placeholder="Write your Thoughts!"
            value={thoughts}
            className="placeholder:text-white font-bold tracking-wide "
            onChange={(e) => setThoughts(e.target.value)}
          />
          <div className="thought-actions">
            <div className="action">
              <div>
                <label style={{ cursor: "pointer" }}>
                  <i className="fa-solid fa-images text-2xl"></i>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div>
                <i className="fa-regular fa-file-lines text-2xl"></i>
              </div>
              <div>
                <i className="fa-regular fa-face-smile text-2xl"></i>
              </div>
              <div>
                <i className="fa-solid fa-file text-2xl"></i>
              </div>
            </div>

            <button onClick={handleAddPost} className="post-btn flex justify-center items-center">
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
