import React, { useState } from 'react'
import './Signup.css'
import { useNavigate } from 'react-router-dom'
import api from '../../../axios'

function Signup() {
  const navigate = useNavigate()

  const [data, setData] = useState({
    email: '',
    password: '',
    confirm_password: '',
    dob: '',
  })
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirm_password: '',
    dob: '',
  })


  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const submitHandler = async () => {
   
    let isError = false
    // email validation 
    if (data.email.trim().length === 0) {
      errors.email = 'email is required'
      isError = true
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email.trim())) {
      errors.email = 'enter a valid email'
      isError = true
    } else {
      errors.email= await api.post('auth/validate-email',{
        email:data.email.trim()
      }).then(res=>{
        return ''
      }).catch(res=>{
        isError=true
        return res.response.data.message
      })
      
    }
    // password validation 
    if (data.password.trim().length === 0) {
      errors.password = 'password is required'
      isError = true
    }else if(data.password.trim().length<8){
      errors.password = 'password need atleast 8 characters'
      isError = true
    } else {
      errors.password = ''
    }
    if (data.confirm_password.trim().length === 0) {
      errors.confirm_password = 'confirm password is required'
      isError = true
    }else if(data.confirm_password.trim()!==data.password.trim()){
      errors.confirm_password = 'passwords are not matching'
      isError = true}
     else {
      errors.confirm_password = ''
    }
    // dob validation
    var today = new Date();
    var birthDate = new Date(data.dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if (data.dob.trim().length === 0) {
      errors.dob = 'Year of birth is required'
      isError = true
    }else if(age<18){
      errors.dob='Only users 18 years or older can create an account.'
      isError = true
    }
     else {
      errors.dob = ''
    }

    if (isError) {
      setErrors({ ...errors })
    } else {
    navigate('/get-mobile', { state: { userData: data } });
    }
  }


  return (
    <div className='page'>
      <div className="form-container">
        <h1 className='heading'>Welcome to OneApp</h1>
        <p className='sub-heading'>Create new account</p>
        <p className='step'>step 1 of 3</p>
        <label className='label'>Email Address</label>
        <input className='input' name='email' type='email'
          placeholder='Enter Your email address'
          onChange={handleChange} value={data.email} />
        {errors.email.length > 0 && <li className='error'>
          {errors.email}
        </li>}
        <label className='label'>Password</label>
        <input
          className='input'
          name='password'
          type='password'
          placeholder='Enter Your Password'
          onChange={handleChange} value={data.password} />
        {errors.password.length > 0 && <li className='error'>
          {errors.password}
        </li>}
        <label className='label'>Confirm Password</label>
        <input
          className='input'
          name='confirm_password'
          type='password'
          placeholder='Confirm Your Password'
          onChange={handleChange} value={data.confirm_password} />
          {errors.confirm_password.length > 0 && <li className='error'>
          {errors.confirm_password}
        </li>}
        <label className='label'>Your birth date</label>
        <input
          className='input'
          name='dob'
          type='date'
          onChange={handleChange} value={data.dob}

        />
        {errors.dob.length > 0 && <li className='error'>
        {errors.dob}
      </li>}

        <div className='btn-group'>
          <button onClick={_ => navigate('/')}>Back</button>
          <button onClick={_ => submitHandler()}>Next</button>
        </div>
      </div>
    </div>
  )
}
export default Signup