import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../axios";

function VerifyOTP() {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  let { userData } = location.state || {};

  const submitHandler = () => {
    if (otp.length === 0) {
      setError("otp is required");
    } else if (otp.length < 6) {
      setError("otp need atleast 6 digit");
    } else {
      axios
        .post("auth/verify-otp", { otp, userData })
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            localStorage.setItem("OneAppJWTtoken", res.data.JWTtoken);
            navigate("/get-username");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // navigate('/get-username')
    }
  };
  return (
    <div className="page">
      <div className="form-container">
        <h1 className="heading">Welcome to OneApp</h1>
        <p className="sub-heading">SMS verification</p>
        <p className="step">step 2 of 3</p>

        <label className="label">Verification code</label>
        <input
          className="input"
          type="number"
          placeholder="XXXXXX "
          value={otp}
          onChange={(e) => {
            if (e.target.value.length <= 6) {
              setOtp(e.target.value);
            }
          }}
        />
        {error.length > 0 && <li className="error">{error}</li>}

        <button onClick={(_) => submitHandler()} className="send-btn">
          verify code
        </button>
        <div className="btn-group">
          <button onClick={(_) => navigate("/get-mobile")}>Back</button>
        </div>
      </div>
    </div>
  );
}

export default VerifyOTP;
