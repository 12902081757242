import React from 'react'
import gallery from '../../Assets/Icons/Image Gallery.svg'
import emoji from '../../Assets/Icons/Happy.svg'
import gif from '../../Assets/Icons/GIF.svg'

const MessageInput = () => {
  return (
    <div className='p-5 w-full text-black relative'>
        <div>
            <input type="text" placeholder='Send a message' className='bg-white w-full py-3 rounded-md px-7 text-2xl placeholder-black' />
        </div>
        <div className='absolute right-10 top-9'>
            <div className='flex gap-2 cursor-pointer'>
                <img src={gallery} alt="" />
                <img src={emoji} alt="" />
                <img src={gif} alt="" />
            </div>
        </div>
    </div>
  )
}

export default MessageInput


