import React, { useContext } from 'react'
import { IoSearch } from "react-icons/io5";
import { BiUser } from "react-icons/bi";
import { MessageContext } from '../../context/MessageContext'

const Primary = () => {

    const { dispatch } = useContext(MessageContext)
 
    const messages = [

        { username: 'user_2', message: 'last test message of first user', time: '2:24 am' },
        { username: 'user_3', message: 'last test message of second user', time: '7:24 pm' },
        { username: 'user_4', message: 'last test message of second user', time: '7:24 pm' },
    ]


  return (
    <div>
      <div className='flex px-5 py-3 relative border-b '>
        <input type="text" className='bg-transparent border w-full px-5 py-1.5 rounded-lg'
          placeholder='Search message'  /> <IoSearch className='absolute text-3xl right-[29px] bottom-[17px] cursor-pointer' />
      </div>

      {/* Users messages */}

      {messages.map((msg, index) => (
        <div 
          key={index}
          onClick={() => dispatch({ type:'SET_SELECT_MESSAGE', payload: msg }) }
          className="message-headers px-4 py-2 flex gap-3 items-center h-[75px] border-b cursor-pointer">
          <div className='border-2 rounded-full w-[47px] h-[40px] flex items-center justify-center'>
            <BiUser className='text-3xl'/>
          </div>

          {/* username and last message */}
          <div className='w-full'>
            <div className='flex justify-between font-bold text-[20px]'>
              <h2>{msg.username}</h2>
              <h2>{msg.time}</h2>
            </div>
            <div>
              <p >{msg.message}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Primary
