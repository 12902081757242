import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "../../../axios";
import { setUserDetails } from "../../../redux/Slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function GetUsername() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const submitHandler = () => {
   
    if (username.trim().length === 0) {
      setError("username is required");
    } else if (username.trim().length < 4) {
      setError("username need atleast 4 digit");
    }else if(!/^[a-zA-Z0-9]+$/.test(username.trim())){
      setError('username only allowed letters and digits')
    }else if(username.trim().length > 15 ){
      setError("username only allowed 15 characters")
    } else if (username.trim().includes(" ")) {
      setError("space is not allowed in username");
    } else {
      const token = localStorage.getItem("OneAppJWTtoken");
      let userId;
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          userId = decodedToken.id;
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      } else {
        console.error("No token found in localStorage");
      }
      console.log("User ID:", userId);

      axios
        .post("auth/save-username", { username, userId })
        .then((res) => {
          if (res.data.status === true) {
            const { _id, email, userName, dateOfBirth, phone, countryCode } =res.data.user;
            dispatch(
              setUserDetails({
                id: _id,
                email: email,
                userName: userName,
                dateOfBirth: dateOfBirth,
                countryCode: countryCode,
                phone: phone,
                role: "user",
              })
            );
            navigate("/home");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="page">
      <div className="form-container">
        <h1 className="heading">Welcome to OneApp</h1>
        <p className="sub-heading">Username!</p>
        <p className="step">step 3 of 3</p>
        <label className="label">Choose your username</label>
        <div className="username-input">
          <p>@</p> &nbsp;
          <input
            type="text"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </div>{" "}
        {error.length > 0 && <li className="error">{error}</li>}
        <div className="btn-group">
          <button onClick={(_) => navigate("/verify-otp")}>Back</button>
          <button onClick={(_) => submitHandler()}>Next</button>
        </div>
      </div>
    </div>
  );
}

export default GetUsername;
