import {  createContext, useReducer, useState } from "react";

// Initial State
const initialState = {
    activeSection : 'F&F',
    selectedMessage : null
}


// Reducer function to handle actions
const messageReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_SECTION' :
            return {
                ...state,
                activeSection: action.payload
            };
        case 'SET_SELECT_MESSAGE':
            return {
                ...state,
                selectedMessage: action.payload
            }
        default:
            return state
    }
}

export const MessageContext = createContext()

export const MessageProvider = ({children}) => {
    // const [activeSection, setActiveSection] = useState('F&F')
    // const [selectedMessage, setSelectedMessage] = useState(null)

    const [state, dispatch] = useReducer(messageReducer, initialState)

    return (
        <MessageContext.Provider value={{ state,dispatch }} >
            {children}
        </MessageContext.Provider>
    )
}