import React, { useEffect, useState } from "react";
import user from "../../Assets/Icons/user-icon.svg";
import axiosInstance from "../../axios";
import Loading from "../Animation/Loading";

const ForYou = ({ data, setData }) => {

  const [loading, setLoading] = useState(true)
  
  const fetchAllPost = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get("/fetch-post");
      console.log(response)
      setData(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchAllPost();
  }, []);  

  return (
    <>
      { !loading ?  (
        data?.map((item, i) => (
        <div key={i} className="border-b ps-24 pr-7 py-4">
          <div className="flex items-center justify-between relative">
            <div className="absolute -left-14 top-[1px]">
              <img src={user} alt="" className="w-[53px]" />
            </div>
            <div className="user-details">
              <h3>Unnecessary Inventions</h3>
              <p>@InsomniacGames</p>
            </div>
            <div className="flex justify-center items-center gap-3">
              <div>
                <button className="follow-btn">Follow</button>
              </div>
              <div>2h</div>
              <div>
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </div>
            </div>
          </div>

          <div className="py-3">
            <p className="break-words">{item.thoughts}</p>
            {/* Conditionally render the image */}
            {item.image && <img src={item.image} alt="Post" className="w-full pt-3" />}
          </div>

          <div className="post-stats">
            <div className="stat">
              <i className="far fa-comment"></i> 5k
            </div>
            <div className="stat">
              <i className="fas fa-retweet"></i> 420
            </div>
            <div className="stat">
              <i className="fa-regular fa-heart"></i> 10k
            </div>
            <div className="stat">
              <i className="far fa-bookmark"></i>
            </div>
            <div className="stat">
              <i className="fa-sharp fa-solid fa-share"></i>
            </div>
          </div>
        </div>
      ))
      ) : (
        <div>
          <Loading />
        </div>
      )
      }
    </>
  );
};

export default ForYou;
