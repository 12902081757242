import React from 'react'
import BasePage from '../../BasePage/BasePage'

const Help = () => {
  return (
    <BasePage content = {
        <div>
            <div className="text-2xl font-bold ps-10 border-b border-white pb-1">
                <h2>Help</h2>
            </div>
            <div className='p-4'>
                <p>this is Help intructions page</p>
            </div>
        </div>
    } />
  )
}

export default Help
