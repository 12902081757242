import React, { useState } from 'react';
import './profile.css'
import BasePage from '../../BasePage/BasePage';
import { Link } from 'react-router-dom';
import { Modal } from "flowbite-react";
import CameraIcon from '../../Assets/Icons/camera.svg'
import BackIcon from '../../Assets/Icons/ArrowLeftWhite.svg'
import { useSelector } from 'react-redux';

function ProfileHeader() {
  const [openModal, setOpenModal] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [profileImage, setProfileImage] = useState()
  const [bannerImg, setBannerImg] = useState('')
  const userInfo = useSelector((state) => state.user.userInfo); 
  console.log(userInfo);
  

  return (
    <div className="profile-header">
      <img src="profile-picture-url" alt="Profile" className="profile-picture" />
      <div className="profile-info">
        <h2>{userInfo.userName}</h2>
        <p>@{userInfo.email}</p>
        <b>Video creator</b>
        <div >
          <p>100K followers</p>
          <button className="edit-btn" onClick={() => setEditProfile(true)}>Edit</button></div>
        <div className="description">
          <p>Everything is example. Design by Nikhil.fsdaffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsdfsdfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff</p>
          <div className='desc-actions'>
            <Link className='desc-more-btn' onClick={() => setOpenModal(true)}>More</Link>
            <Link className='desc-links'>Links</Link>
          </div>
        </div>
        <Modal show={editProfile} onClick={(e) => {
          setEditProfile(false)
        }} onClose={() => setEditProfile(false)} className='ep-modal-bg'>

          <Modal.Body onClick={(e) => {
            e.stopPropagation()
          }} className='modal ep-model'>
            <div className="edit-profile ">
              <div className='ep-header'>
                <img src={BackIcon} className='ep-back-btn'  onClick={_=> setEditProfile(false)}/>
              <p className='ep-heading'>Edit Profile</p>
              </div>
              <div className='ep-banner' style={{
                backgroundImage: `url(${bannerImg ? URL.createObjectURL(bannerImg) : CameraIcon})`,
                backgroundSize: bannerImg && 'cover'
              }}
                onChange={e => setBannerImg(e.target.files[0])}
              >
                <input type='file' accept='image/*' />
                {/* <img src={CameraIcon}/> */}
              </div>
              <div className='ep-profile-img' style={{ backgroundImage: `url(${profileImage ? URL.createObjectURL(profileImage) : CameraIcon})`, backgroundSize: profileImage && 'cover' }}>
                <input type='file' accept='image/*' onChange={e => setProfileImage(e.target.files[0])} />

              </div>
              {/* <img className='ep-banner' alt='banner' src={'https://images.pexels.com/photos/573130/pexels-photo-573130.jpeg?cs=srgb&dl=pexels-zyuliansyah-573130.jpg&fm=jpg'}/> */}
              <input placeholder='Enter Name' className='name-input' />
              <div className="ep-username-input">
                <p>@</p> &nbsp;
                <input
                  type="text"
                  placeholder='Username'
                  //value={username}
                  // onChange={(e) => {
                  //   setUsername(e.target.value);
                  // }}
                />
              </div>{" "}
                <textarea placeholder='Description'  className='desc-input' />
                <input type='url' placeholder='Link' className='link-input'/>
                <input type='text' placeholder='Professional account' className='acc-type-input'/>
              <div className='ep-btn-group'>
                <button onClick={_=> setEditProfile(false)}>back</button>
                <button>save</button>
              </div>
            </div>
          </Modal.Body>

        </Modal>
        <Modal show={openModal} onClick={(e) => {
          setOpenModal(false)
        }} onClose={() => setOpenModal(false)} className='modal-bg'>

          <Modal.Body onClick={(e) => {
            e.stopPropagation()
          }} className='modal'>
            <div className="about-content">
              <p className='about-heading'>About</p>
              <p className="desc-more">
                With less than a month to go before the European Union enacts new consumer privacy laws for its citizens,
                companies around the world are updating their terms of service agreements to comply.

                The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant
                to ensure a common set of data rights in the European Union. It requires organizations to notify users as
                soon as possible of high-risk data breaches that could personally affect them.
              </p>

              <p className='about-heading'>Links</p>
              <div className='input-group'>
                <input type="checkbox" name="" id="" />
                <input type="checkbox" name="" id="" /></div>
            </div>
          </Modal.Body>

        </Modal>
        {/*  */}
      </div>
    </div>
  );
}

const ActionButtons = () => (
  <div className="action-buttons">
    {/* <button className="message-btn">Message</button>
    <button className="links-btn">More Links</button> */}
  </div>
);

const Tabs = ({ activeTab, setActiveTab }) => (
  <div className="tabs">
    {['Thoughts'
      //  , 'Moments', 'Beeps', 'Videos'
    ].map((tab) => (
      <button
        key={tab}
        className={activeTab === tab ? 'active' : ''}
        onClick={() => setActiveTab(tab)}
      >
        {tab}
      </button>
    ))}
  </div>
);

const Content = ({ activeTab }) => {
  switch (activeTab) {
    case 'Thoughts':
      return <div>Thoughts Content</div>;
    // case 'Moments':
    //   return <div>Moments Content</div>;
    // case 'Beeps':
    //   return <div>Beeps Content</div>;
    // case 'Videos':
    //   return <div>Videos Content</div>;
    default:
      return null;
  }
};

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('Thoughts');

  return (
    <BasePage content={
      <div className="profile-container">
        <ProfileHeader />
        <ActionButtons />
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <Content activeTab={activeTab} />
      </div>} />
  );
};
export default UserProfile;