import React from 'react'
import BasePage from '../../BasePage/BasePage'

const Thoughts = () => {
  return (
    <BasePage content = {
        <div>
            This is Toughts Page
        </div>
    } />
  )
}

export default Thoughts


