import React from 'react'
import './BasePage.css'
import Header from '../components/Header/Header'
import Sidedraw from '../components/Sidedraw/Sidedraw'

function BasePage({ ...props }) {

    return (
        <div className='home-page' style={{ ...props.style }}>
            <Header />
            <Sidedraw />
            <div className='home-content' style={{ ...props.contentStyle }}>
                {props.content}

            </div>

        </div>
    )
}

export default BasePage