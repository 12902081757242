import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Sidedraw.css'
import thoughtsIcon from '../../Assets/Icons/Thoughts.svg'

import messagesIcon from '../../Assets/Icons/Messages.svg'

import savedIcon from '../../Assets/Icons/Saved.svg'

import editProfileIcon from '../../Assets/Icons/EditProfile.svg'
import SettingsIcon from '../../Assets/Icons/Settings.svg'


import helpIcon from '../../Assets/Icons/Help.svg'
import logo from '../../Assets/Brandings/logo_large.png'


function Sidedraw() {
    const [isOpen, setOpen] = useState(false)
    

    return (
        <>
            <div className='sidedraw '>
                <div className='sidedraw-grid'>

                    <Link to="/home" className='sidedraw-cell'>
                        <img src={thoughtsIcon} alt='thoughts' />
                        <p>Thoughts</p>
                    </Link>

                    <Link to="/message" className='sidedraw-cell'>
                        <img src={messagesIcon} alt='messages' />
                        <p>Messages</p>
                    </Link>

                    <Link to="/saved" className='sidedraw-cell'>
                        <img src={savedIcon} alt='saved' />
                        <p>Saved</p>
                    </Link>

                    <Link to="/profile" className='sidedraw-cell'>
                        <img src={editProfileIcon} alt='edit profile' />
                        <p>Edit Profile</p>
                    </Link>






                    <Link to="/settings" className='sidedraw-cell'>
                        <img src={SettingsIcon} alt='settings' />
                        <p>Settings</p>
                    </Link>


                    <Link to="/help" className='sidedraw-cell'>
                        <img src={helpIcon} alt='help' />
                        <p>Help</p>
                    </Link>

                </div>



            </div>

            
        </>
    )
}

export default Sidedraw