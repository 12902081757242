import React, { useState } from 'react'
import BasePage from '../../BasePage/BasePage'

const Saved = () => {

    const [isSaved,setIsSaved] = useState(true)

    const handleSaved = () => {
        setIsSaved(true)
    }
    const handleLiked = () => {
        setIsSaved(false)
    }
    

  return (
    <BasePage content = {
        <div className=''>
            <div className='sections flex gap-56 pt-4 ps-36 border-b border-white '>
                <div onClick={handleSaved} className= {`cursor-pointer ${ isSaved ? 'border-b-2 font-bold' : '' }`} >
                    <p>Saved</p>
                </div>
                <div onClick={handleLiked} className={`cursor-pointer ${ !isSaved ? 'border-b-2 font-bold' : '' } `}>
                    <p >Liked</p>
                </div>
            </div>

            <div className="content">
                {
                    isSaved ? (
                        <div>
                            <h3 className='text-white'>
                                This is <b>Saved</b> Posts Section
                            </h3>
                        </div>
                    ) : (
                        <div>
                            <h3 className='text-white'>
                                This is <b>Liked</b> Posts Section
                            </h3>
                        </div>
                    )
                }
            </div>
        </div>
    } />
  )
}

export default Saved
